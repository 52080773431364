var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"updateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var touched = ref.touched;
return [_c('form',{staticClass:"authentication-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfile)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('TextInput',{attrs:{"name":"first_name","rules":"required","label":"First Name"},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}})],1),_c('div',{staticClass:"col-md-6"},[_c('TextInput',{attrs:{"name":"last_name","rules":"required","label":"Last Name"},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}})],1),_c('div',{staticClass:"col-md-6"},[_c('TextInput',{attrs:{"name":"email","rules":"required|email","label":"Email"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)]),_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loadingUpdate || (invalid && touched),"type":"submit"}},[(_vm.loadingUpdate)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Update")])],1)],1)],1)]}}])})],1)])])]),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ValidationObserver',{ref:"passwordForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
var touched = ref.touched;
return [_c('form',{staticClass:"authentication-form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('TextInput',{attrs:{"name":"password","rules":"required|confirmed:confirm_password","label":"Password","type":"password"},model:{value:(_vm.password.password),callback:function ($$v) {_vm.$set(_vm.password, "password", $$v)},expression:"password.password"}})],1),_c('div',{staticClass:"col-md-6"},[_c('TextInput',{attrs:{"vid":"confirm_password","name":"confirm_password","rules":"required","label":"Confirm Password","type":"password"},model:{value:(_vm.password.password_confirmation),callback:function ($$v) {_vm.$set(_vm.password, "password_confirmation", $$v)},expression:"password.password_confirmation"}})],1)]),_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loadingPassword || (invalid && touched),"type":"submit"}},[(_vm.loadingPassword)?_c('b-spinner',{attrs:{"small":""}}):_c('span',[_vm._v("Change")])],1)],1)],1)]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row page-title align-items-center"},[_c('div',{staticClass:"col-sm-4 col-xl-6"},[_c('h4',{staticClass:"mb-1 mt-0"},[_vm._v("Profile")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row page-title align-items-center"},[_c('div',{staticClass:"col-sm-4 col-xl-6"},[_c('h4',{staticClass:"mb-1 mt-0"},[_vm._v("Change password")])])])}]

export { render, staticRenderFns }