<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Profile</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="updateForm">
              <form class="authentication-form" @submit.prevent="handleSubmit(updateProfile)">
                <div class="row">
                  <div class="col-md-6">
                    <TextInput v-model="user.first_name" name="first_name" rules="required" label="First Name" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="user.last_name" name="last_name" rules="required" label="Last Name" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="user.email" name="email" rules="required|email" label="Email" />
                  </div>
                </div>
                <b-form-group>
                  <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <div class="row page-title align-items-center">
      <div class="col-sm-4 col-xl-6">
        <h4 class="mb-1 mt-0">Change password</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="passwordForm">
              <form class="authentication-form" @submit.prevent="handleSubmit(changePassword)">
                <div class="row">
                  <div class="col-md-6">
                    <TextInput v-model="password.password" name="password" rules="required|confirmed:confirm_password"
                      label="Password" type="password" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="password.password_confirmation" vid="confirm_password" name="confirm_password"
                      rules="required" label="Confirm Password" type="password" />
                  </div>
                </div>
                <b-form-group>
                  <b-button variant="primary" :disabled="loadingPassword || (invalid && touched)" type="submit">
                    <b-spinner v-if="loadingPassword" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      loadingUpdate: false,
      loadingPassword: false,
      user: {
        agency: {
          twilioConfig: {},
          whitelabel: {},
        },
      },
      password: {
        password: '',
        password_confirmation: '',
      },
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user'])
  },

  methods: {
    updateProfile() {
      this.loadingUpdate = true

      this.$store
        .dispatch('auth/updateProfile', this.user)
        .then(() => {
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    changePassword() {
      this.loadingPassword = true

      this.$store
        .dispatch('user/changePassword', this.password)
        .then(() => {
          this.password = {}
          this.loadingPassword = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingPassword = false
        })
    },
  },
}
</script>
